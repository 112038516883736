import React, { useState, useEffect } from 'react';
import { getAllCosts } from '../services/apiService';
import AddCost from './AddCost';
import Modal from './Modal';
import '../styles/Accounts.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import the FontAwesomeIcon component
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
const COST_TYPE_OPTIONS = ["Income", "Expense"];
const COST_CATEGORY_OPTIONS = [
  "Rent",
  "Salaries",
  "CleaningMaterials",
  "Utilities",
  "Maintenance",
  "FoodSupplies",
  "CollateralPayment",
  "OppositeEntry",
  "Other"
];

const FilterPills = ({ filters, removeFilter }) => {
  const activeFilters = Object.entries(filters).filter(
    ([key, value]) => value && key !== 'boardingHouseId'
  );

  if (activeFilters.length === 0) return null;

  return (
    <div className="account-filter-pills">
      {activeFilters.map(([key, value]) => (
        <div key={key} className="filter-pill-secondary">
          <div className="filter-key-value">
            {key}: {value}
          </div>
          <div className="remove-filter" onClick={() => removeFilter(key)}>
            <FontAwesomeIcon className="clear-filter-icon" onClick={() => removeFilter(key)} icon={faXmark} />
          </div>
          {/* <FontAwesomeIcon className="small-clear-filter-results-icon" onClick={() => removeFilter(key)} icon={faXmark} /> */}

        </div>
      ))}
    </div>
  );
};

const AccountList = ({ boardingHouseId }) => {
  const [costs, setCosts] = useState([]);
  const [filters, setFilters] = useState({
    boardingHouseId: '',
    type: '',
    category: '',
    fromDate: '',
    toDate: '',
    minAmount: '',
    maxAmount: '',
    noteToken: ''
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const openModalWithContent = (content) => {
    setModalContent(content);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalContent(null);
  };

  const fetchCosts = async () => {
    try {
      const response = await getAllCosts(
        boardingHouseId,
        filters.type || null,
        filters.category || null,
        null,
        filters.minAmount || null,
        filters.maxAmount || null,
        filters.noteToken || null,
      );

      const filteredCosts = response.data.$values.filter(cost => {
        const costDate = new Date(cost.date);
        const fromDate = filters.fromDate ? new Date(filters.fromDate) : null;
        const toDate = filters.toDate ? new Date(filters.toDate) : null;

        return (
          (!fromDate || costDate >= fromDate) &&
          (!toDate || costDate <= toDate)
        );
      });

      setCosts(filteredCosts);
    } catch (error) {
      //console.error('Error fetching costs:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCosts();
  }, [filters, boardingHouseId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  // check if any filter has non-empty value
  const isAnyFilterSet = Object.values(filters).some((filterValue) => filterValue);

  const removeFilter = (filterKey) => {
    setFilters({
      ...filters,
      [filterKey]: ''
    });
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading rooms: {error.message}</div>;

  return (
    <div className="accounts-container">
      <div className="title-and-new-entry">
        <h3 className="">Accounts</h3>
        <button className="primary-btn" onClick={() => openModalWithContent(<AddCost boardingHouseId={boardingHouseId} fetchRooms={fetchCosts} setError={setError} />)}>Add</button>
      </div>

      <div className="filter-bar">
        <h3>Filters</h3>
        <div className="filters">
          <div className="select-combo">
            <select name="type" value={filters.type} onChange={handleInputChange}>
              <option value="">Select Type</option>
              {COST_TYPE_OPTIONS.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>

            <select name="category" value={filters.category} onChange={handleInputChange}>
              <option value="">Select Category</option>
              {COST_CATEGORY_OPTIONS.map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>
          <div className="date-combo">
            <input
              type="date"
              name="fromDate"
              placeholder="From Date"
              value={filters.fromDate}
              onChange={handleInputChange}
            />
            <div className="duration">To</div>
            <input
              type="date"
              name="toDate"
              placeholder="To Date"
              value={filters.toDate}
              onChange={handleInputChange}
            />
          </div>

          <div className="amount-combo">
            <input
              type="number"
              name="minAmount"
              placeholder="Min Amount"
              value={filters.minAmount}
              onChange={handleInputChange}
            />
            <div className="duration">To</div>
            <input
              type="number"
              name="maxAmount"
              placeholder="Max Amount"
              value={filters.maxAmount}
              onChange={handleInputChange}
            />
          </div>
          <div className="search-desc">
            <input
              type="text"
              name="noteToken"
              maxlength="40"
              placeholder="Search Description"
              value={filters.noteToken}
              onChange={handleInputChange}
            />
          </div>

        </div>
        <div className="applied-filter-pills">
          <FilterPills filters={filters} removeFilter={removeFilter} />
        </div>


      </div>


      <div className="accounts">
        <div className="accounts-list">
          {costs.map((cost, index) => (
            <div >
              <div key={cost.id}>
                {/* <div className="cost-type-col">{COST_TYPE_OPTIONS[cost.type]}</div> */}

                <div className="account-entry">

                  <div className="account-details-left">

                    <div className="account-index-amount">
                      <div className="account-index">{index + 1}.</div>
                      <div className="account-amount">{cost.amount}</div>

                    </div>

                    <div className="account-type-house-date">
                      <div className="account-type">{COST_TYPE_OPTIONS[cost.type]}</div>
                      {/* <div className="account-house">House, Room</div> */}
                      <div className="account-date">{new Date(cost.date).toLocaleDateString()}</div>

                    </div>
                    {/* <div className="client-phone">
                      {cost.description}
                    </div> */}

                  </div>

                  <div className="account-details-right">

                    <div className="account-description">{cost.description}</div>


                  </div>

                </div>



                {/* <td className="category-col">{COST_CATEGORY_OPTIONS[cost.category]}</td> */}

              </div>
            </div>
          ))}
        </div>
      </div>

      {/* <div className="total-amount">
        <h4>Total Amount: {costs.reduce((sum, cost) => sum + cost.amount, 0)}</h4>
      </div> */}

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        {modalContent}
      </Modal>
    </div>
  );
};


export default AccountList;
