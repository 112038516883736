import React, { useState } from 'react';
import { register } from '../services/apiService'; // Import the register function from your axios service
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import '../styles/Register.css';
import { toast  } from 'react-toastify';

const Ortenticurga = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();



    const handleRegister = async (e) => {
        var user = {
            username,
            email,
            password
        }
        e.preventDefault();
        try {
            const response = await register(user);
            toast.success(`User created successfully`);
            navigate('/login'); // Redirect to the login page after successful registration
        } catch (err) {
            //setError('Registration failed');
            toast.error(`Registration failed`);
        }
    };

    return (
        <div className="register-container">

            <div className="brand-box">
                <h1 className="brand-title">SpaceKudu</h1>
                <h2 className="brand-motto">Manage and track your properties <br />with ease.</h2>
            </div>
            <div className="right-side">
                <h2 className="register-title">Register An Account</h2>
                <form className="register-box" onSubmit={handleRegister}>

                    <div className="register-input-group">

                        {error && <p className="error-p">{error}</p>}
                        <input
                            type="text"
                            placeholder="Username"
                            value={username}
                            required
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        <input
                            placeholder="Email"
                            type="email"
                            value={email}
                            required
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            required
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <button className="primary-btn register-button" type="submit">Register</button>
                        <hr className="register-hr"></hr>
                        <p className="register-p"><Link to="/login">Already have an account?</Link></p>
                    </div>

                </form>
            </div>

        </div>

    );
};

export default Ortenticurga;
