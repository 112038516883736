import React, { useState } from 'react';
import { createBoardingHouse } from '../services/apiService';
import { toast  } from 'react-toastify';

const AddBoardingHouse = ({ setError }) => {
    //const [StudentId, setStudentId] = useState('');
    const [name, setName] = useState('');
    const [location, setLocation] = useState('');

    const handleAddBoardingHouse = async () => {

        if (!name.trim() || !location.trim()) {
            //alert('All fields are required');
            toast.error(`All fields are required`);
            return;
        }
        const boardingHouse = {
            name,
            location
        };


        try {
            await createBoardingHouse(boardingHouse);
            //setStudentId('');
            toast.success(`Property added successfully!`);
            setName('');
            setLocation('');
            window.location.reload();
        } catch (error) {
            setError(error);
        }
    };

    return (
        <div className="add-bh input-group">
            <div className='component-title'>Add Property</div>
            <div className="lable-and-input">
                <label>Property Name</label>
                <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="name"
                />
            </div>

            <div className="lable-and-input">
                <label>Property Location</label>
                <input
                    type="text"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    placeholder="location"
                />
            </div>

            <button className="primary-btn" onClick={handleAddBoardingHouse}>Add Property</button>
        </div>
    );
};

export default AddBoardingHouse;
