import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Modal from './Modal';
import AddBoardingHouse from './AddBoardingHouse';
import PrivateRoute from './PrivateRoute'; // Import PrivateRoute
import '../styles/SidebarTest.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faBars, faInfo, faXmark } from '@fortawesome/free-solid-svg-icons'
import { faHouseUser } from '@fortawesome/free-solid-svg-icons'
import { faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { faSquarePlus } from '@fortawesome/free-solid-svg-icons'
import { faSquareCaretDown } from '@fortawesome/free-solid-svg-icons'
import { faTableCells } from '@fortawesome/free-solid-svg-icons'
import { faBook } from '@fortawesome/free-solid-svg-icons'
// import { faAngleDown } from '@fortawesome/free-solid-svg-icons'

const SidebarTest = ({ BoardingHouseDropList }) => {

  const [isCollapsed, setIsCollapsed] = useState(false);

  // const toggleSidebar = () => {
  //   setIsCollapsed(!isCollapsed);
  // };

  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [error, setError] = useState(null);

  // Function to open the modal
  const openModalWithContent = (content) => {

    setModalContent(content);
    setModalOpen(true);
  };
  // Function to close the modal
  const closeModal = () => {
    setModalOpen(false);
    setModalContent(null);
  };

  //  ctrl for dropdown
  const [isOpen, setIsOpen] = useState(false);

  // Function to toggle dropdown open/closed
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Function for when an option is clicked
  const handleOptionClick = (option) => {
    //console.log(`You selected ${option}`);
    // Add logic here for handling the option selection
    setIsOpen(false); // Close the dropdown after selection
  };

  // new
  const openNav = () => {
    setIsOpen(true);
  };

  const closeNav = () => {
    setIsOpen(false);
  };

  // old
  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div>
      <div className={`sidenav ${isOpen ? 'open' : ''}`}>
        {/* <a href="#" className="closebtn" onClick={closeNav}>&times;</a> */}
        <div className="sidebar-title">
          <div className="custom-h2">Dashboard</div>
          <div className="sidebar-button" onClick={closeNav}><FontAwesomeIcon className="icon-class-xl" icon={faXmark} />
          </div>
        </div>

        <div className="sidebar-ul">

          <div className="sidebar-li">
            <Link onClick={() => { openModalWithContent(<AddBoardingHouse setError={setError} />); closeNav() }}><FontAwesomeIcon className="icon-class" icon={faSquarePlus} /></Link>
            <Link className="sidebar-link" onClick={() => { openModalWithContent(<AddBoardingHouse setError={setError} />); closeNav() }}>Add Property</Link>
          </div>
          <div className="sidebar-li">
            <Link onClick={openNav}><FontAwesomeIcon className="icon-class" icon={faSquareCaretDown} /></Link>
            <Link className="sidebar-link" >{BoardingHouseDropList && <BoardingHouseDropList />}</Link>
          </div>
          <div className="sidebar-li">
            <Link to="/rooms" onClick={closeNav}><FontAwesomeIcon className="icon-class" icon={faHouseUser}/></Link>
            <Link className="sidebar-link" to="/rooms" onClick={closeNav}>Rooms</Link>
          </div>
          <div className="sidebar-li">
            <Link className="sidebar-link" to="/Clients" onClick={closeNav}><FontAwesomeIcon className="icon-class" icon={faUsers} /></Link>
            <Link className="sidebar-link" to="/Clients" onClick={closeNav}>Clients</Link>
          </div>
          <div className="sidebar-li">
            <Link className="sidebar-link" to="/Accounts" onClick={closeNav}><FontAwesomeIcon className="icon-class" icon={faFileInvoiceDollar} /></Link>
            <Link className="sidebar-link" to="/Accounts" onClick={closeNav}>Account Records</Link>
          </div>

          {/* new dropdown */}
          <div className="sidebar-li" >{/*onClick={toggleDropdown} */}
            <div className="sidebar-li">
              <Link className="sidebar-link" ><FontAwesomeIcon className="icon-class" icon={faBook} /></Link>
              <Link className="sidebar-link drop-down-title" >Reports</Link>
              <Link className="sidebar-link" ><FontAwesomeIcon className="icon-class small-icon" icon={faAngleDown} /></Link>
            </div>
            {isOpen && (
              <div className="dropdown">
                {<div className="sidebar-li">
                  <Link className="sidebar-link" to="/AccountsReport" onClick={closeNav}><FontAwesomeIcon className="icon-class" icon={faTableCells} /></Link>
                  <Link className="sidebar-link" to="/AccountsReport" onClick={closeNav}>Account Reports</Link>
                </div>}
                <div className="sidebar-li">
                  <Link className="sidebar-link" to="/VacancyReport" onClick={closeNav}><FontAwesomeIcon className="icon-class" icon={faTableCells} /></Link>
                  <Link className="sidebar-link" to="/VacancyReport" onClick={closeNav}>Vacancy Reports</Link>
                </div>
              </div>
            )}
          </div>
          {/* end of new dropdown */}

          <div className="sidebar-li">
            <Link className="sidebar-link" to="/Info" onClick={closeNav}><FontAwesomeIcon className="icon-class" icon={faInfo} /></Link>
            <Link className="sidebar-link" to="/Info" onClick={closeNav}>Property Info...</Link>
          </div>

          <Modal isOpen={isModalOpen} onClose={closeModal}>
            {modalContent}
          </Modal>

        </div>

      </div>
      <div className="bars" onClick={openNav}>&#9776;</div>
    </div>
  );
};

export default SidebarTest;
