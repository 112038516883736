import { useState, useEffect } from 'react';
import { getBoardingHouseById, getRoomsByBoardingHouseId, UpdateBoardingHouseDetails, deleteBoardingHouse } from '../services/apiService';
import '../styles/info.css';
import { FaBuilding, FaDoorOpen, FaUsers, FaMoneyBill, FaBed } from 'react-icons/fa'; // Import icons
import { toast } from 'react-toastify';

function Info({ boardingHouseId }) {
    const [boardingHouse, setBoardingHouse] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [email, setEmail] = useState('');
    const [location, setLocation] = useState('');
    const [name, setName] = useState('');
    const [rooms, setRooms] = useState([]);

    useEffect(() => {
        fetchBoardingHouseInfo(boardingHouseId);
    }, [boardingHouseId]);

    useEffect(() => {
        fetchRooms();
    }, [boardingHouseId]);

    const fetchBoardingHouseInfo = async () => {
        try {
            const result = await getBoardingHouseById(boardingHouseId);
            const house = result.data;
            setBoardingHouse(house);
            setName(house.name);
            setEmail(house.email);
            setLocation(house.location);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const fetchRooms = async () => {
        try {
            const result = await getRoomsByBoardingHouseId(boardingHouseId);
            const roomsData = result.data.$values;

            if (Array.isArray(roomsData)) {
                const flattenedRooms = flattenRooms(roomsData);
                setRooms(flattenedRooms);
            } else {
                throw new Error('Unexpected data structure');
            }
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const flattenRooms = (roomsArray) => {
        const roomsMap = {};
        const addRoomToMap = (room) => {
            if (!roomsMap[room.id]) {
                roomsMap[room.id] = room;
            }
        };
        roomsArray.forEach(room => {
            if (room.$ref) {
                return;
            }
            addRoomToMap(room);
            if (room.boardingHouse?.rooms?.$values) {
                room.boardingHouse.rooms.$values.forEach(nestedRoom => {
                    if (nestedRoom.$ref) {
                        const refId = nestedRoom.$ref.split("/")[1];
                        const referencedRoom = roomsArray.find(r => r.$id === refId);
                        addRoomToMap(referencedRoom);
                    } else {
                        addRoomToMap(nestedRoom);
                    }
                });
            }
        });
        return Object.values(roomsMap);
    };

    const handleUpdateProperty = async () => {
        setLoading(true); // Set loading to true while updating

        try {
            // Update the boarding house details by calling the API function
            const updatedHouse = {
                // id: boardingHouseId,  // Pass the current boarding house ID
                name,                 // Updated name
                email,                // Updated email
                location              // Updated location
            };

            // Call the API service to update the boarding house
            await UpdateBoardingHouseDetails(boardingHouseId, updatedHouse);

            // Fetch updated data after the update
            fetchBoardingHouseInfo(boardingHouseId);
            toast.success('Boarding house updated successfully!');
        } catch (error) {
            //console.error('Failed to update boarding house:', error);
            setError('Failed to update boarding house');
        } finally {
            setLoading(false); // Stop loading after the update
        }
    };

    const handleDeleteProperty = async () => {
        const confirmDelete = window.confirm('Are you sure you want to delete this property? This action cannot be undone.');

        if (confirmDelete) {
            setLoading(true); // Set loading to true while deleting

            try {
                // Call the API service to delete the boarding house
                await deleteBoardingHouse(boardingHouseId);

                // You might want to handle navigation or inform the user
                toast.success('Boarding house deleted successfully!');

                // Optionally, redirect to another page after deletion
                // history.push('/boarding-houses');
            } catch (error) {
                //console.error('Failed to delete boarding house:', error);
                setError('Failed to delete boarding house');
            } finally {
                setLoading(false); // Stop loading after the deletion
            }
        }
    };


    return (
        <div className="info-container">
            <div className="property">
                <div className="property-info">
                    <div className="update-property">
                        <div className="input-items">
                            <div className="component-title">Update Property</div>
                            <div className="label-and-input">
                                <label>Name</label>
                                <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="e.g House 5" />
                            </div>
                            <div className="label-and-input">
                                <label>Location</label>
                                <input type="text" value={location} onChange={(e) => setLocation(e.target.value)} placeholder="e.g Lusaka" />
                            </div>
                            <div className="label-and-input">
                                <label>Email</label>
                                <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="e.g myEmail@gmail.com" />
                            </div>
                            <div className="label-and-input">
                                <button className="primary-btn" onClick={handleUpdateProperty}>Update Property</button>
                                <button className="primary-btn delete-btn" onClick={handleDeleteProperty}>Delete Property  </button>
                            </div>


                        </div>

                    </div>

                </div>

                <div className="property-stats">
                    <div className="info-card">
                        <FaBuilding className="icon" />
                        <div><span className="hide-on-mobile">Number of </span>Rooms: {rooms.length}</div>
                    </div>
                    <div className="info-card">
                        <FaDoorOpen className="icon" />
                        <div><span className="hide-on-mobile">Number of </span>Empty Rooms: {rooms.filter(room => room.students.$values.length === 0).length}</div>
                    </div>
                    <div className="info-card">
                        <FaUsers className="icon" />
                        <div><span className="hide-on-mobile">Max </span>Capacity: {rooms.reduce((total, room) => total + room.capacity, 0)}<span className="hide-on-mobile">Clients</span> </div>
                    </div>
                    <div className="info-card">
                        <FaMoneyBill className="icon" />
                        <div> <span className="hide-on-mobile">Max</span>Expected <span className="hide-on-mobile">Rent</span>: K {rooms.reduce((total, room) => {
                            const capacity = Number(room.capacity) || 0;
                            const cost = Number(room.cost) || 0;
                            return total + (capacity * cost);
                        }, 0)}</div>
                    </div>
                    <div className="info-card">
                        <FaBed className="icon" />
                        {/* <div>Room Types: {[...new Set(rooms.map(room => room.capacity))].map(capacity => `${capacity} space room`).join(', ')}</div> */}
                        <div>Room Types:5</div>
                    </div>

                    <div className="info-card">
                        <FaBed className="icon" />
                        {/* <div>Room Types: {[...new Set(rooms.map(room => room.gender))].map(gender => `${gender} space room`).join(', ')}</div> */}
                        <div>Room Types:</div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Info;
