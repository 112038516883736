import React, { useState, useEffect } from 'react';
import { getRoomById, UpdateRoomDetails, deleteRoom } from '../services/apiService';
import '../styles/Modal.css';
import { toast  } from 'react-toastify';

const UpdateRoom = ({ roomId, fetchRooms, setError }) => {
    const [name, setName] = useState('');
    const [capacity, setCapacity] = useState('');
    const [gender, setGender] = useState(false); // Boolean state for gender
    const [roomDetails, setRoomDetails] = useState({});
    const [cost, setCost] = useState(null);
    const [securityDeposit, setSecurityDeposit] = useState(null)
    const [isEdited, setIsEdited] = useState(false);

    useEffect(() => {
        const fetchRoomDetails = async () => {
            try {
                const result = await getRoomById(roomId);
                setRoomDetails(result.data);
            } catch (error) {
                setError(error);
            }
        };
        fetchRoomDetails();
    }, [roomId, setError]);

    useEffect(() => {
        if (roomDetails) {
            setName(roomDetails.name || '');
            setCapacity(roomDetails.capacity || '');
            setGender(roomDetails.gender || false);
            setCost(roomDetails.cost || '')
            setSecurityDeposit(roomDetails.securityDeposit || '')
        }
    }, [roomDetails]);

    const checkIfEdited = () => {
        if (
            name !== roomDetails.name ||
            capacity !== roomDetails.capacity ||
            gender !== roomDetails.gender ||
            cost !== roomDetails.cost ||
            securityDeposit !== roomDetails.securityDeposit
        ) {
            setIsEdited(true);
        } else {
            setIsEdited(false);
        }
    };

    useEffect(() => {
        checkIfEdited();
    }, [name, capacity, gender, cost, securityDeposit]);

    const handleUpdateRoom = async () => {
        if (!name.trim() || !capacity || gender === null || !cost || !securityDeposit) {
            //alert('All fields are required');
            toast.error(`All fields are required`);
            return;
        }
        
        // Show confirmation dialog
        const confirmUpdate = window.confirm('Are you sure you want to update the room details?');
        if (!confirmUpdate) return; // Exit if user cancels

        const room = {
            name: name,
            capacity: capacity,
            gender: gender,
            cost: cost,
            securityDeposit : securityDeposit
        };

        //console.log(room);

        try {
            await UpdateRoomDetails(roomId, room);
            toast.success(`${room.name} updated successfully!`);
            await fetchRooms();
            setIsEdited(false);
        } catch (error) {
            setError(error);
        }
    };

    const handleDeleteRoom = async () => {
        // Show confirmation dialog
        const confirmDelete = window.confirm('Are you sure you want to delete this room? This action cannot be undone.');
        if (!confirmDelete) return; // Exit if user cancels
        try {
            await deleteRoom(roomId);
            toast.success(`${name} deleted successfully!`);
            await fetchRooms();
        }
        catch (error){
            toast.error(`Failed to delete ${name}`);
            //setError(error);
        }
    }

    const toggleGender = () => {
        setGender((prevGender) => !prevGender);
    };

    return (
        <div className="update-room input-group">
            <div className='component-title'>Update Room</div>
            <div className="lable-and-input">
                <label>Room Name </label>
                <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Room Name"
                />
            </div>

            <div className="lable-and-input">
                <label>Capacity</label>
                <input
                    type="number"
                    value={capacity}
                    onChange={(e) => setCapacity(e.target.value)}
                    placeholder="Capacity"
                />
            </div>
            
            <div className="lable-and-input">
                <label>Cost</label>
                <input
                    type="number"
                    value={cost}
                    onChange={(e) => setCost(e.target.value)}
                    placeholder="Cost"
                />
            </div>

            <div className="lable-and-input">
                <label>Security Deposit</label>
                <input
                    type="number"
                    value={securityDeposit}
                    onChange={(e) => setSecurityDeposit(e.target.value)}
                    placeholder="Security Deposit"
                />
            </div>

            <div>
                <label onClick={toggleGender} style={{ cursor: 'pointer' }}>
                    {gender ? 'Female' : 'Male'}
                </label>
            </div>
            <button className="primary-btn" onClick={handleUpdateRoom} disabled={!isEdited}>Update Room</button>

            <div>
            <button className="primary-btn delete-btn" onClick={handleDeleteRoom} >Delete Room</button>
            </div>
        </div>
        
    );
};

export default UpdateRoom;
