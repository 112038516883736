import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { createStudentForRoom, getStudentsWithoutRoom, UpdateStudentDetails, createRentForStudent } from '../services/apiService';
import { toast } from 'react-toastify';

const AddStudent = ({ roomId, boardinghouseId, fetchRooms, setError, closeModal }) => {
    const [studentsWR, setStudentsWR] = useState([]);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [IDNumber, setIDNumber] = useState('');
    const [DOE, setDOE] = useState('');

    useEffect(() => {
        const fetchStudentsWR = async () => {
            try {
                const result = await getStudentsWithoutRoom(boardinghouseId);
                setStudentsWR(
                    result.data.$values.map((student) => ({
                        value: student.idNumber,
                        label: `${student.fname} ${student.lname} - ${student.idNumber}`,
                        ...student,
                    }))
                );
            } catch (error) {
                setError(error);
            }
        };
        fetchStudentsWR();
    }, [boardinghouseId, setError]);

    const handleAddStudent = async () => {
        if (!selectedStudent) {
             
            
            if (!fname.trim() || !lname.trim() || !IDNumber.trim() || !DOE.trim()) {
                toast.error("All fields are required");
                return;
            }
            const student = {
                fname,
                lname,
                idNumber: IDNumber,
                dateOfEntry: DOE,
                boardinghouseId: boardinghouseId
            };
            try {
                await createStudentForRoom(roomId, student);
                //closeModal();
                notify();
                setFname('');
                setLname('');
                setIDNumber('');
                setDOE('');
                await fetchRooms(roomId); // Re-fetch the rooms to update the list with the new student
                
            } catch (error) {
                setError(error);
            }

        }
        else {

            const student = {
                id: selectedStudent.id,
                boardingHouseId: selectedStudent.boardingHouseId,
                fname: selectedStudent.fname,
                lname: selectedStudent.lname,
                idNumber: selectedStudent.idNumber,
                dateOfEntry: selectedStudent.dateOfEntry,
                numberOfWarnings: selectedStudent.numberOfWarnings,
                roomId: roomId
            };  
    
            console.log("updated student", student)
            
            // Get today's date
            const today = new Date();
        
            const rent = {
                StudentId:  selectedStudent.id,
                Amount: null, // Set the rent amount to null
                StartDate: today.toISOString(), // Set today's date as StartDate
                EndDate: today.toISOString() // Optionally, use the same date for EndDate, or adjust as needed
            };
        
            try {
                // Record the rent with null amount and today's date
                await createRentForStudent(selectedStudent.id, rent);
            } catch (error) {
                setError(error); // Handle any errors
                toast.error(error);
            }
                   
    
            try {
                await UpdateStudentDetails(selectedStudent.id, student);
                toast.success(`${fname} ${lname} succesfully updated!`);
                setFname('');
                setLname('');
                setIDNumber('');
                setDOE('');
                await fetchRooms(); 
                
            } catch (error) {
                setError(error);
                toast.error(`Failed to Update ${fname} ${lname}`);
            }
        }

    };

    const notify = (fname, lname) => {
        toast.success(`${fname} ${lname} added successfully!`);
    };

    return (
        <div className="add-student input-group">
            <div className="component-title">Select from Clients</div>
            <div id="lable-input-select" className="label-and-input">
                <label>Select Client</label>
                <Select className="selector-sec"
                    options={studentsWR}
                    value={selectedStudent}
                    onChange={(selectedOption) => setSelectedStudent(selectedOption)}
                    placeholder="Search and select a student"
                    isClearable
                />
            </div>
            <div className="component-title">Add New Client</div>
            <div className="lable-and-input">
                <label>First Name </label>
                <input
                    type="text"
                    value={fname}
                    required
                    disabled={!!selectedStudent} // Disable if a client is selected
                    onChange={(e) => setFname(e.target.value)}
                    placeholder="First name"
                />
            </div>

            <div className="lable-and-input">
                <label>Last Name </label>
                <input
                    type="text"
                    value={lname}
                    required
                    disabled={!!selectedStudent} // Disable if a client is selected
                    onChange={(e) => setLname(e.target.value)}
                    placeholder="Last name"
                />
            </div>

            <div className="lable-and-input">
                <label>Phone Number</label>
                <input
                    type="text"
                    value={IDNumber}
                    disabled={!!selectedStudent} // Disable if a client is selected
                    onChange={(e) => setIDNumber(e.target.value)}
                    placeholder=" e.g 0978108835"
                />
            </div>

            <div className="lable-and-input">
                <label>Date </label>
                <input
                    type="date"
                    value={DOE}
                    required
                    disabled={!!selectedStudent} // Disable if a client is selected
                    onChange={(e) => setDOE(e.target.value)}
                    placeholder="Date Of Entry"
                />
            </div>

            <button className="primary-btn" onClick={handleAddStudent}>
            {!selectedStudent? "Add Client"  : `Add ${selectedStudent.fname} ${selectedStudent.lname}`}
            </button>
        </div>
    );
};

export default AddStudent;
