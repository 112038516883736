import React, { useEffect, useState } from 'react';
import { getRepairDetails, UpdateRepairDetails } from '../services/apiService';
import { toast  } from 'react-toastify';


const UpdateRepair = ({ repairId, fetchRooms, setError }) => {
    //const [StudentId, setStudentId] = useState('');
    const [notes, setNotes] = useState('');
    const [cost, setCost] = useState('');
    const [state, setState] = useState(false);
    const [repairDetails, setRepairDetails] = useState([]);

    useEffect(() => {
        const fetchRepairDetails = async () => {
            const result = await getRepairDetails(repairId);
            setRepairDetails(result.data);
            //console.log(result);
        };
        fetchRepairDetails();

    }, [repairId]);

    useEffect(() => {
        setCost(repairDetails.cost);
        setNotes(repairDetails.notes);
        setState(repairDetails.repairsComplete)

    }, [repairDetails])

    //console.log(repairDetails)

    const handleUpdateRepair = async () => {
        if (!notes.trim()) {
            toast.error(`All fields are required`);
            return;
        }
        const repair = {
            notes: notes,
            cost: parseFloat(cost) || 0, // Ensure cost is a number
        };

        //console.log('Repair object:', repair);

        try {
            await UpdateRepairDetails(repairId, repair);
            //setStudentId('');
            toast.success(`Repair resolved!`);
            setNotes('');
            setCost('');


            await fetchRooms(); // Re-fetch the rooms to update the list with the new repair
        } catch (error) {
            setError(error);
        }
    };

    return (
        <div className="update-repair input-group">

            <div className="lable-and-input">
                <label>Notes About Repair </label>
                <input
                    type="text"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    placeholder="notes"
                />
            </div>
            <div className="lable-and-input">
                <label> Cost</label>
                <input
                    type="text"
                    readOnly={(state === true) ? true : false}
                    disabled={(state === true) ? true : false}
                    value={cost}
                    onChange={(e) => setCost(e.target.value)}
                    placeholder="cost"
                />
            </div>

            {(!state) && <button className="primary-btn" onClick={handleUpdateRepair}>Mark as Fixed</button>}

        </div>
    );
};

export default UpdateRepair;
