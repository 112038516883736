import React, { useState, useEffect, useRef } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../styles/Calender.css';
import '../styles/CalendarModal.css';

const MyCalendar = ({ rooms }) => {
  const [value, setValue] = useState(new Date());
  const [eventDetails, setEventDetails] = useState([]);
  const [peopleOwing, setPeopleOwing] = useState([]);
  const [activeMonth, setActiveMonth] = useState(new Date());
  const listItemRefs = useRef([]); // Store refs for each list item

  useEffect(() => {
    const lastRentEndDates = [];

    const roomsArray = rooms; // Ensure rooms is an array

    roomsArray.forEach((room) => {
      room.students.$values.forEach((student) => {
        let rents = student.rents.$values;
        if (rents.length > 0) {
          let lastRent = rents[rents.length - 1];/*.reduce((latest, rent) => {
            const latestDate = new Date(latest.endDate);
            const rentDate = new Date(rent.endDate);
            return rentDate > latestDate ? rent : latest;
          });*/
          // Store the last endDate with the student's name
          lastRentEndDates.push({
            date: new Date(lastRent.endDate),
            name: `${student.fname} ${student.lname}`,
            room: room.name,
            id: `${student.idNumber}`,
            amount: /*lastRent.amount*/  room.cost
          });
        }
      });
    });

    // Update the eventDetails state with the last rent end dates and names
    setEventDetails(lastRentEndDates);
  }, [rooms]);

  // Check if a date has an event
  const isEventDay = (date) => {
    return eventDetails.some(
      (eventDetail) =>
        eventDetail.date.getFullYear() === date.getFullYear() &&
        eventDetail.date.getMonth() === date.getMonth() &&
        eventDetail.date.getDate() === date.getDate()
    );
  };

  // Get event details for a specific date
  const getEventDetails = (date) => {
    return eventDetails.filter(
      (eventDetail) =>
        eventDetail.date.getFullYear() === date.getFullYear() &&
        eventDetail.date.getMonth() === date.getMonth() &&
        eventDetail.date.getDate() === date.getDate()
    );
  };

  // Filter people owing for the selected month and sort by date in ascending order
  useEffect(() => {
    const month = activeMonth.getMonth(); // selected month
    const year = activeMonth.getFullYear(); // selected year

    const peopleOwingThisMonth = eventDetails
      .filter(
        (eventDetail) =>
          eventDetail.date.getMonth() === month &&
          eventDetail.date.getFullYear() === year
      )
      .sort((a, b) => a.date - b.date); // Sort in ascending order by date

    setPeopleOwing(peopleOwingThisMonth);
  }, [activeMonth, eventDetails]);

  // Handle month change (when the user navigates to a different month)
  const handleMonthChange = ({ activeStartDate }) => {
    setActiveMonth(activeStartDate); // Update the active month
  };

  // Add custom tile content
  const tileContent = ({ date, view }) => {
    if (view === 'month' && isEventDay(date)) {
      const events = getEventDetails(date);
      return (
        <div className="event-dot" title={events.map(event => `${event.name}: ${event.date.toLocaleDateString()}`).join(', ')}>
          {/* <span className="tooltip">
            {events.map(event => `${event.name} - ${event.date.toLocaleDateString()}`).join(', ')}
          </span> */}
        </div>
      );
    }
  };

  // Check if the selected date matches any item in the owing list
  const isHighlighted = (person) => {
    return (
      value.getFullYear() === person.date.getFullYear() &&
      value.getMonth() === person.date.getMonth() &&
      value.getDate() === person.date.getDate()
    );
  };

  // Scroll the highlighted list item into view when date is clicked
  useEffect(() => {
    const highlightedIndex = peopleOwing.findIndex((person) => isHighlighted(person));

    if (highlightedIndex !== -1 && listItemRefs.current[highlightedIndex]) {
      listItemRefs.current[highlightedIndex].scrollIntoView({
        behavior: 'smooth', // Smooth scrolling
        block: 'nearest', // Scroll to the nearest point
      });
    }
  }, [value, peopleOwing]);

  return (
    <div className="calendar-page">
      <div className="calendar-container">
        <Calendar
          onChange={setValue}
          value={value}
          tileContent={tileContent}
          onActiveStartDateChange={handleMonthChange} 
        />
      </div>

      <div className="owing-list-container">

        <div className="owing-title">
          <h3>Payments Due {activeMonth.toLocaleString('default', { month: 'long' })} {activeMonth.getFullYear()}</h3>
        </div>
        <div className="owing-list">
          <div className="owing-ul">
            {peopleOwing.length > 0 ? (
              peopleOwing.map((person, index) => (
                <div
                  key={index}
                  ref={(el) => (listItemRefs.current[index] = el)} 
                  className={`owing-li ${isHighlighted(person) ? 'highlighted' : ''}`}
                >
                  {person.name} - {person.id} <br></br> {person.room}, K{person.amount} (Due: {person.date.toLocaleDateString()})
                </div>
              ))
            ) : (
              <div className="owing-li">No one owes rent this month.</div>
            )}
            <div className="owing-li-bottom-pad"></div>
          </div>
          <div className="space"></div>
        </div>

      </div>
    </div>
  );
};

export default MyCalendar;
