// Modal.js
import React from 'react';
import '../styles/CalendarModal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotate } from '@fortawesome/free-solid-svg-icons'

const CalendarModal = ({ children, isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="calendar-modal-overlay" onClick={onClose}>

            <div className="calendar-modal-content" onClick={e => e.stopPropagation()}>

                <div className="modal-close-button">
                    <div>
                        <FontAwesomeIcon icon={faRotate} />
                    </div>
                    <button className="close-button" onClick={onClose}>X</button>
                </div>

                <div className="calendar-modal-children">
                    {children}
                </div>
            </div>

        </div>
    );
};

export default CalendarModal;
