
import React, { useState, useEffect } from 'react';
import { createRoomForBoardingHouse } from '../services/apiService';
import { toast } from 'react-toastify';

const AddRoom = ({ boardingHouseId, fetchRooms, setError }) => {
    const [newRoomName, setNewRoomName] = useState('');

    const handleAddRoom = async () => {
        if (!newRoomName.trim()) {
            //alert('Room name cannot be empty');
            toast.error(`All fields are required`);
            return;
        }
        try {
            await createRoomForBoardingHouse(boardingHouseId, { name: newRoomName }); // Adjust the payload as needed
            toast.success(`${newRoomName} added successfully!`);
            setNewRoomName(''); // Clear the input field
            await fetchRooms(); // Re-fetch the rooms after adding
        } catch (error) {
            toast.error(`Failed to add ${newRoomName}`);
            setError(error);
        }
    };
    return (
        <div className="input-group">
            <div className='component-title'>Add Room</div>
            <div className="lable-and-input">
                <label>Room Name</label>
                <input
                    type="text"
                    value={newRoomName}
                    onChange={(e) => setNewRoomName(e.target.value)}
                    placeholder="Enter room name"
                />
            </div>

            <button className="primary-btn" onClick={handleAddRoom}>Add Room</button>
        </div>
    )
}

export default AddRoom;