import React, { useEffect, useState } from 'react';
import { getRoomsByBoardingHouseId, createRoomForBoardingHouse, getRentDetailsByStudentId, createRepairForRoom, UpdateRoomDetails } from '../services/apiService';
import AddStudent from './AddStudent'; // Import AddStudent as default
import AddRent from './AddRent'; // Import AddStudent as default
import AddRepair from './AddRepair';
import UpdateRepair from './UpdateRepair';
import UpdateRoom from './UpdateRoom';
import StudentRentDetails from './StudentRentDetails'
import Modal from './Modal';
import CalendarModal from './CalendarModal';
import '../styles/RoomListM.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareXmark, faSquareCheck, faCalendar, faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons'
import { faSquarePlus } from '@fortawesome/free-solid-svg-icons'
import { faRectangleXmark } from '@fortawesome/free-solid-svg-icons'
import UpdateStudent from './UpdateStudent';
import AddRoom from './AddRoom'
import Calendar from './Calender';
import { differenceInMonths, addMonths } from 'date-fns';
import { toast } from 'react-toastify';




const RoomListM = ({ boardingHouseId }) => {
    const [rooms, setRooms] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [isModalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [selectedRooms, setSelectedRooms] = useState([]);
    const [searchTerm, setSearchTerm] = useState(''); // State to store search term
    const [isCalender, setIsCalender] = useState(false);
    const [visibleRepairs, setVisibleRepairs] = useState({});
    const [visibleRepairsCount, setVisibleRepairsCount] = useState({});

    // Function to toggle visibility for a specific room
    const toggleVisibility = (roomId) => {
        setVisibleRepairs((prev) => ({
            ...prev,
            [roomId]: !prev[roomId],
        }));
    };

    // Function to increase the visible repairs count by 2 for a specific room
    const showMoreRepairs = (roomId) => {
        setVisibleRepairsCount((prev) => ({
            ...prev,
            [roomId]: (prev[roomId] || 2) + 2,
        }));
    };


    // Function to open the modal
    const openModalWithContent = (content) => {
        setModalContent(content);
        setModalOpen(true);
    };
    // Function to close the modal
    const closeModal = () => {
        setModalOpen(false);
        setModalContent(null);
        setIsCalender(false);
    };

    const [currentStudentId, setCurrentStudentId] = useState(null);

    const fetchRooms = async () => {
        try {
            const result = await getRoomsByBoardingHouseId(boardingHouseId);
            //console.log('API Result:', result); // Debugging: log the API result
            const roomsData = result.data.$values; // Adjusting to your data structure

            if (Array.isArray(roomsData)) {
                // Flattening the rooms array to include references
                const flattenedRooms = flattenRooms(roomsData);
                setRooms(flattenedRooms);
            } else {
                throw new Error('Unexpected data structure');
            }
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    // Function to flatten rooms data structure
    const flattenRooms = (roomsArray) => {
        const roomsMap = {};

        const addRoomToMap = (room) => {
            if (!roomsMap[room.id]) {
                roomsMap[room.id] = room;
            }
        };

        roomsArray.forEach(room => {
            if (room.$ref) {
                // If it's a reference, it should already be in the map
                return;
            }
            addRoomToMap(room);

            // If the room has nested rooms, add them as well
            if (room.boardingHouse?.rooms?.$values) {
                room.boardingHouse.rooms.$values.forEach(nestedRoom => {
                    if (nestedRoom.$ref) {
                        // Handle references if needed
                        const refId = nestedRoom.$ref.split("/")[1];
                        const referencedRoom = roomsArray.find(r => r.$id === refId);
                        addRoomToMap(referencedRoom);
                    } else {
                        addRoomToMap(nestedRoom);
                    }
                });
            }
        });

        return Object.values(roomsMap);
    };

    useEffect(() => {
        fetchRooms();
    }, [boardingHouseId]);

    // State to track which button is active ('boy' or 'girl')
    const [activeButton, setActiveButton] = useState('');

    const handleButtonClick = (gender) => {
        // If the button clicked is already active, reset the activeButton to ''
        setActiveButton(prevState => (prevState === gender ? '' : gender));
    };

    const [filters, setFilters] = useState('');
    const handleSearch = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        setFilters({
            value
        });
    };

    const removeFilter = (filterKey) => {
        setFilters({});
        setSearchTerm("");
    };

    // Filter rooms by room name or client name
    const filteredRooms = rooms.filter((room) => {
        const roomNameMatches = room.name.toLowerCase().includes(searchTerm.toLowerCase());
        const clientMatches = room.students?.$values.some((student) =>
            `${student.fname} ${student.lname}`.toLowerCase().includes(searchTerm.toLowerCase())
        );
        const genderMatches = filters.gender === undefined || (room.gender === filters.gender) && ((room.capacity - room.students.$values.length) > 0);

        return (roomNameMatches || clientMatches) && genderMatches;
    });

    // A function to format the date
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }).format(date);
    };

    // Utility function to determine rent status color
    const getRentStatusIndicator = (endDate) => {
        const today = new Date();
        const rentEndDate = new Date(endDate);

        //console.log(endDate);
        //console.log(today);
        if (rentEndDate < today) {

            return 'red-indicator'; // Rent is overdue
        } else if (rentEndDate > today) {
            return 'green-indicator'; // Rent is paid or not yet due
        } else {
            return 'gray-indicator';
        }

    };

    function mergePayments(paymentHistory) {
        // Create an object to store the merged periods
        let mergedPeriods = {};

        // Iterate over each payment in the payment history
        paymentHistory.forEach(({ startDate, endDate, amount }) => {
            // Create a unique key for the period using both start and end dates
            const key = `${startDate}_${endDate}`;

            // If the period already exists, add the amount to it
            if (mergedPeriods[key]) {
                mergedPeriods[key] += amount;
            } else {
                // Otherwise, create a new entry
                mergedPeriods[key] = amount;
            }
        });

        // Convert the object back into an array format
        let result = [];
        for (let [key, amount] of Object.entries(mergedPeriods)) {
            // Correctly extract startDate and endDate by splitting on underscore (_)
            let [startDate, endDate] = key.split('_');
            result.push({ startDate, endDate, amount });
        }

        return result;
    }

    function calculatePaymentStatus(paymentHistory, rentPerMonth, securityDeposit) {
        let accumulatedOverpayment = 0;
        let accumulatedUnderpayment = 0;

        paymentHistory = mergePayments(paymentHistory);

        // Create a map to track total payments per month
        let monthlyPayments = {};

        paymentHistory.forEach(payment => {
            let { startDate, endDate, amount } = payment;

            // Adjust start and end dates to ensure they align with monthly billing
            let start = new Date(startDate);
            let end = new Date(endDate);

            // Calculate the total number of days covered by the payment
            let timeDifference = end - start;
            let totalDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24)) + 1;

            // Calculate how many full months are covered by the payment
            let fullMonths = Math.floor(totalDays / 30);
            let extraDays = totalDays % 30;

            // Calculate the amount that should have been paid for the full months
            let amountDueForFullMonths = fullMonths * rentPerMonth;

            // if extra days are greater than 5, calculate the amount due for them
            let amountDueForExtraDays = (extraDays >= 5) ? extraDays * (rentPerMonth / 30) : 0;

            // Total amount due for the full period (months + extra days)
            let totalAmountDue = amountDueForFullMonths + Math.round(amountDueForExtraDays);

            // Round the amounts
            amount = Math.round(amount);

            // Track payments by month
            for (let monthOffset = 0; monthOffset < fullMonths + (extraDays > 0 ? 1 : 0); monthOffset++) {
                let monthKey = `${start.getFullYear()}-${start.getMonth() + 1 + monthOffset}`; // e.g., "2024-9" for September 2024
                if (!monthlyPayments[monthKey]) {
                    monthlyPayments[monthKey] = 0;
                }
                monthlyPayments[monthKey] += amount; // Sum the amounts for that month
            }

            // Compare accumulated payments
            if (amount > totalAmountDue) {
                // Overpayment: accumulate the overpaid amount
                accumulatedOverpayment += amount - totalAmountDue;
            } else if (amount < totalAmountDue) {
                // Underpayment: accumulate the underpaid amount
                accumulatedUnderpayment += totalAmountDue - amount;
            }

            // Offset overpayments and underpayments
            if (accumulatedOverpayment > 0 && accumulatedUnderpayment > 0) {
                let offset = Math.min(accumulatedOverpayment, accumulatedUnderpayment);
                accumulatedOverpayment -= offset;
                accumulatedUnderpayment -= offset;
            }
        });

        // Determine the next month's rent based on accumulated overpayments or underpayments
        let amountDueNextMonth;
        let paymentStatus;

        if (accumulatedOverpayment > 0) {
            paymentStatus = "Overpayment";
            amountDueNextMonth = Math.max(0, rentPerMonth - accumulatedOverpayment); // Deduct overpayment from next month's rent
        } else if (accumulatedUnderpayment > 0) {
            paymentStatus = "Underpayment";
            amountDueNextMonth = rentPerMonth + accumulatedUnderpayment; // Add underpayment to next month's rent
        } else {
            paymentStatus = "Exact payment";
            amountDueNextMonth = rentPerMonth; // Regular rent for next month
        }

        // Round the next payment amount
        amountDueNextMonth = Math.round(amountDueNextMonth) + securityDeposit;

        // Assuming the next payment date is based on the last payment end date
        //let lastPaymentEndDate = new Date(paymentHistory[paymentHistory.length - 1][1]);
        let lastPaymentEndDate = new Date(paymentHistory[paymentHistory.length - 1].endDate);

        let nextPaymentDate = new Date(lastPaymentEndDate);
        nextPaymentDate.setDate(nextPaymentDate.getDate() + 1); // Start next payment the day after last payment

        return {
            paymentStatus,
            amountDueNextMonth,
            nextPaymentDate: nextPaymentDate.toDateString(),
            accumulatedOverpayment,
            accumulatedUnderpayment
        };
    }

    function RentStatus({ studentId, roomCost, securityDeposit, rentDetails }) {
        const today = new Date();

        if (rentDetails.length === 0) return <div className="occupant-balance">-</div>;

        let { paymentStatus, amountDueNextMonth, nextPaymentDate, accumulatedOverpayment, accumulatedUnderpayment } = calculatePaymentStatus(rentDetails, roomCost, securityDeposit);

        return (
            <div className="occupant-balance">k{amountDueNextMonth}00</div>
        );
    }

    function NextPayment({ studentId, roomCost, securityDeposit, rentDetails }) {
        const today = new Date();

        if (rentDetails.length === 0) return <div className="occupant-payment-date">No Payment History</div>;

        let { paymentStatus, amountDueNextMonth, nextPaymentDate, accumulatedOverpayment, accumulatedUnderpayment } = calculatePaymentStatus(rentDetails, roomCost, securityDeposit);

        return (
            <div className="occupant-payment-date">Due: {nextPaymentDate}</div>
        );
    }

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error loading rooms: {error.message}</div>;

    const handleRoomSelection = (roomId) => {
        // Append room id to selectedRooms array if not already present
        setSelectedRooms((prevSelectedRooms) => {
            if (!prevSelectedRooms.includes(roomId)) {
                return [...prevSelectedRooms, roomId];
            }
            return prevSelectedRooms;
        });
    };

    const FilterPills = ({ filters, removeFilter }) => {
        const activeFilters = Object.entries(filters).filter(
            ([key, value]) => value && key !== 'boardingHouseId'
        );

        if (activeFilters.length === 0) return null;

        return (
            <div className="filter-pills">
                {activeFilters.map(([key, value]) => (
                    <span key={key} className="filter-pill">
                        {/* {key}: {value} */}
                        {/* <button className="clear-filter-results-btn" onClick={() => removeFilter(key)}>x</button> */}
                        <FontAwesomeIcon className="clear-filter-results-icon" onClick={() => removeFilter(key)} icon={faRectangleXmark} />
                    </span>
                ))}
            </div>
        );
    };




    return (
        <div className="mobile-container">
            
            <div className="search-area">
                <div className="input-area">
                    <input
                        type="text"
                        placeholder="Search room or client name..."
                        value={searchTerm}
                        onChange={handleSearch} f
                    />
                    <div className="clear-search">
                        <FilterPills filters={filters} removeFilter={removeFilter} />
                    </div>
                </div>
            </div>
            <div className="quick-actions">
                <div class="card">
                    <div className={`card-item ${activeButton === 'female' ? 'female-active' : ''}`}
                        onClick={() => {
                            // Toggle logic: if the current filter is already set to female, clear it; otherwise, set to female
                            setFilters((prevFilters) => prevFilters.gender === true ? {} : { gender: true });
                            // Toggle isClicked state
                            handleButtonClick('female');
                        }}
                    >
                        <span class="full-text">Female</span>
                        <div class="card-stat">
                            {rooms.filter(room => room.gender === true).reduce((sum, room) => sum + (room.capacity - room.students.$values.length), 0)}
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div className={`card-item ${activeButton === 'male' ? 'male-active' : ''}`}
                        onClick={() => {
                            // Toggle logic: if the current filter is already set to male, clear it; otherwise, set to male
                            setFilters((prevFilters) => prevFilters.gender === false ? {} : { gender: false });
                            // Toggle isClicked state
                            handleButtonClick('male');
                        }}
                    >
                        <span class="full-text">Male</span>
                        <div class="card-stat">
                            {rooms.filter(room => room.gender === false).reduce((sum, room) => sum + (room.capacity - room.students.$values.length), 0)}
                        </div>
                    </div>
                </div>
                <div class="card" onClick={() => { setIsCalender(true); openModalWithContent(<Calendar rooms={rooms} />) }}>
                    <div class="card-item">
                        Calendar
                        <div class="card-stat">
                            <FontAwesomeIcon className="    " icon={faCalendar} size='' /></div>
                    </div>
                </div>
                <div class="card" onClick={() => { openModalWithContent(<AddRoom boardingHouseId={boardingHouseId} fetchRooms={fetchRooms} setError={setError} />) }}>
                    <div class="card-item">
                        Room
                        <div class="card-stat">
                            <FontAwesomeIcon className="" icon={faSquarePlus} size='' /></div>
                    </div>
                </div>
                {/*<div class="card">
                    <div class="card-item">
                        Costs
                        <div class="card-stat">3</div>
                    </div>
                </div>*/}
            </div>

            {/* <div className="">
                <div className="" onClick={() => { openModalWithContent(<AddRoom boardingHouseId={boardingHouseId} fetchRooms={fetchRooms} setError={setError} />) }}>
                    <FontAwesomeIcon className="icon-class blue-icons" icon={faSquarePlus} size='lg' />
                    <a>Add Room</a>
                </div>
            </div> */}

            {filteredRooms.length > 0 ? (
                filteredRooms.map((room, index) => (

                    <div key={room.id} className="room">
                        <div className="room-titles">
                            <div className="room-titles-name">
                                <p onClick={() => { openModalWithContent(<UpdateRoom roomId={room.id} fetchRooms={fetchRooms} setError={setError} />) }}>{room.name} </p>
                            </div>
                            <div className="room-titles-space">
                                <p onClick={() => { openModalWithContent(<UpdateRoom roomId={room.id} fetchRooms={fetchRooms} setError={setError} />) }}>free space: {room.capacity - room.students.$values.length}</p>
                            </div>
                            <div className="room-titles-gender">
                                <p onClick={() => { openModalWithContent(<UpdateRoom roomId={room.id} fetchRooms={fetchRooms} setError={setError} />) }}>{room.gender ? 'Female' : 'Male'}</p>

                            </div>
                            <div className="room-titles-edit">
                                <p onClick={() => { openModalWithContent(<UpdateRoom roomId={room.id} fetchRooms={fetchRooms} setError={setError} />) }}>Edit</p>
                            </div>
                        </div>
                        <div className="room-occupants">
                            {room.students?.$values?.map(student => (

                                <div className={`room-occupant ${getRentStatusIndicator(student.rents?.$values.sort((a, b) => new Date(a.endDate) - new Date(b.endDate))[(student.rents?.$values.length) - 1]?.endDate)}`} >

                                    <div className="occupant-names"><a onClick={() => { openModalWithContent(<UpdateStudent rooms={rooms} studentId={student.id} fetchRooms={fetchRooms} setError={setError} />) }}>{student.fname} {student.lname} Simukongo</a></div>
                                    <RentStatus studentId={student.id} roomCost={room.cost} securityDeposit={room.securityDeposit} rentDetails={student.rents?.$values} onClick={() => openModalWithContent(<StudentRentDetails studentId={student.id} student={student} fetchRooms={fetchRooms} setError={setError} />)} />
                                    {/* <div className="occupant-balance" onClick={() => openModalWithContent(<StudentRentDetails studentId={student.id} student={student} fetchRooms={fetchRooms} setError={setError} />)}>200000 </div> */}
                                    <div className="occupant-payment-date" onClick={() => openModalWithContent(<StudentRentDetails studentId={student.id} student={student} fetchRooms={fetchRooms} setError={setError} />)}> <NextPayment studentId={student.id} roomCost={room.cost} securityDeposit={room.securityDeposit} rentDetails={student.rents?.$values} onClick={() => openModalWithContent(<StudentRentDetails studentId={student.id} student={student} fetchRooms={fetchRooms} setError={setError} />)} /></div>
                                    <div className="add-occupant-payment" onClick={() => openModalWithContent(<AddRent student={student} boardingHouseId={boardingHouseId} roomName={room.name} lastDueDate={student.rents?.$values.sort((a, b) => new Date(a.endDate) - new Date(b.endDate))[(student.rents?.$values.length) - 1]?.endDate} roomCost={room.cost} securityDeposit={room.securityDeposit} fetchRooms={fetchRooms} setError={setError} />)}>
                                        <FontAwesomeIcon className="icono" icon={faSquarePlus} size='lg' />
                                    </div>



                                </div>


                            ))}

                            <div className="room-buttons">

                                {((room.capacity - room.students.$values.length) !== 0) ?
                                    <button className="primary-btn" onClick={() => openModalWithContent(<AddStudent roomId={room.id} boardinghouseId={boardingHouseId} fetchRooms={fetchRooms} setError={setError} closeModal={closeModal} />)}>Add Client</button> :
                                    <button className="primary-btn" onClick={() => toast.error(`Oops, No Free Space in ${room.name} `, { position: 'bottom-center' })} >Add Client</button>}
                                {/* <div className="add-repair-button"> <button className="primary-btn" onClick={() => openModalWithContent(<AddRepair RoomId={room.id} fetchRooms={fetchRooms} setError={setError} />)}>Add Repair</button></div> */}
                                <div className="add-repair-button"> <button className="primary-btn" onClick={() => toggleVisibility(room.id)}>{visibleRepairs[room.id] ? 'Hide Repairs' : 'Repairs'}</button></div>
                            </div>

                        </div>

                        {visibleRepairs[room.id] && (
                            <div className="repair">
                                {room.repairs?.$values
                                    ?.slice(0, visibleRepairsCount[room.id] || 2)
                                    ?.map((repair) => (
                                        <div key={repair.id} style={{
                                            color: repair.repairsComplete ? 'green' : 'red'
                                        }}
                                            onClick={() => openModalWithContent(<UpdateRepair repairId={repair.id} fetchRooms={fetchRooms} setError={setError} />)}
                                        >
                                            {repair.repairsComplete ?
                                                (<FontAwesomeIcon icon={faSquareCheck} />) : (<FontAwesomeIcon textAnchor='resolved' icon={faSquareXmark} />)
                                            }
                                            {repair.notes.substring(0, 15)} {(repair.notes.length > 15) ? "..." : ""} -
                                            {formatDate(repair.dateOfReport)} -

                                        </div>

                                    ))}
                                {room.repairs?.$values.length > (visibleRepairsCount[room.id] || 2) && (
                                    <button className="show-more-btn" onClick={() => showMoreRepairs(room.id)}>
                                        Show More
                                    </button>
                                )}
                                <div className="add-repair-button"> <button className="primary-btn" onClick={() => openModalWithContent(<AddRepair RoomId={room.id} fetchRooms={fetchRooms} setError={setError} />)}>Add Repair</button></div>

                            </div>)}
                    </div>
                ))
            ) : (
                <div>No rooms available</div>
            )}
            {(isCalender) ? <CalendarModal isOpen={isModalOpen} onClose={closeModal}>
                {modalContent}
            </CalendarModal> :
                <Modal isOpen={isModalOpen} onClose={closeModal}>
                    {modalContent}
                </Modal>}

            <div className="bottom-pad"></div>
        </div>

    );
};

export default RoomListM;
