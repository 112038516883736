import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import '../styles/Register.css';

const Register = () => {

    return (
        <div className="main-sign-in-container">
            <div className="register-container">

                <div className="brand-box">
                    <h1 className="brand-title">SpaceKudu</h1>
                    <h2 className="brand-motto">Manage and track your properties <br />with ease.</h2>
                </div>
                <div className="right-side">
                    <h2 className="register-title">Registering An Account</h2>
                    <form className="register-box">

                        <div className="register-input-group">
                            <p className="register-p to-register">To Register An Account, Contact Us:</p>
                            <p className="register-p"><a href="tel:+260770028215">Call: +260 770028215</a></p>
                            <p className="register-p"><a href="mailto:zeronetechnologieslimited@gmail.com">Email:&nbsp;<span className="email">zeronetechnologieslimited@gmail.com</span></a></p>
                            <hr className="register-hr"></hr>
                            <p className="register-p"><Link to="/login">Already have an account?</Link></p>
                        </div>

                    </form>
                </div>

            </div>
            <div className="privacy-and-about">
                <Link to="/Privacy">Privacy</Link>
            </div>
            <div className="privacy-and-about">
                <a target="_blank" href="https://zerone-technologies-limited.github.io/index.html">&#169;ZerOne Technologies Limited | 2024</a>

            </div>
        </div>

    );
};

export default Register;
