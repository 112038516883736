import { useState, useEffect } from 'react';
import React from 'react';
import { Route, Routes, useNavigate, redirect } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import SidebarTest from './components/SidebarTest';
import Dashboard from './components/Dashboard';
import RoomList from './components/RoomList';
import RoomListM from './components/RoomListM';
import StudentList from './components/StudentList';
import AccountsList from './components/AccountsList';
import Login from './components/Login';
import Register from './components/Register';
import Ortenticurga from './components/Ortenticurga';
import Logout from './components/Logout';
import { getBoardingHouses } from './services/apiService';
import PrivateRoute from './components/PrivateRoute'; // Import PrivateRoute
import RentList from './components/RentList';
import RepairList from './components/RepairList';
import VacancyReport from './components/VacancyReport';
import AccountsReport from './components/AccountsReport';
import Privacy from './components/Privacy';
import About from './components/About';
import './App.css';
import { Bounce, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Info from './components/Info';
import { Navigate } from 'react-router-dom';

function App() {
    const [boardingHouses, setBoardingHouses] = useState([]);
    const [selectedBoardingHouseId, setSelectedBoardingHouseId] = useState(null);
    const [loading, setLoading] = useState(true);
    //const navigate = useNavigate(); // Hook to navigate programmatically

    function checkTokenExpiration() {
        const expirationTime = localStorage.getItem('expirationTime');
        const currentTime = Date.now();
        //const navigate = useNavigate();

        if (currentTime > expirationTime || !expirationTime) {
            localStorage.removeItem('token');
            localStorage.removeItem('expirationTime');
            return <Navigate to="/login" replace />;//navigate('/login');  // Correct programmatic navigation
            //return redirect("/login");
        } else {
            return <Navigate to="/rooms" replace />;//navigate('/rooms');
            //return  redirect("/rooms");
        }
    }

    // Check token expiration periodically
    useEffect(() => {
        const interval = setInterval(checkTokenExpiration, 6000000); // Check every 60 seconds
        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, []);

    useEffect(() => {
        checkTokenExpiration();
        if (selectedBoardingHouseId === null) { fetchBoardingHouses(); }
    }, []);

    const fetchBoardingHouses = async () => {
        try {
            const result = await getBoardingHouses();
            const houses = result.data.$values; // Adjusting to your data structure
            setBoardingHouses(houses);
            if (houses.length > 0) {
                setSelectedBoardingHouseId(houses[0].id); // Set the first boarding house as default
            }
        } catch (error) {
            return <Navigate to="/login" replace />;//navigate('/login'); // Redirect on error
            //redirect("/login");
        } finally {
            setLoading(false);
        }
    };

    const handleBoardingHouseChange = (e) => {
        e.stopPropagation(); // Prevent the click event from bubbling up
        setSelectedBoardingHouseId(e.target.value);
    };

    //  ctrl for dropdown
    const [isOpen, setIsOpen] = useState(false);

    // Function to toggle dropdown open/closed
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    // Function for when an option is clicked
    const handleOptionClick = (option) => {
        console.log(`You selected ${option}`);
        // Add logic here for handling the option selection
        setIsOpen(false); // Close the dropdown after selection
    };

    const BoardingHouseDropList = () => {
        return (

            <div className="bh-dropdown">

                <select className="drop-down" value={selectedBoardingHouseId || ''} onChange={handleBoardingHouseChange} >
                    <option className="dd-option" value="" disabled>Select a Property</option>
                    {boardingHouses.map((boardingHouse) => (
                        (boardingHouse.id !== selectedBoardingHouseId.id) ?
                            <option className="dd-option" key={boardingHouse.id} value={boardingHouse.id}>
                                {boardingHouse.name}
                            </option>
                            :
                            <option className="dd-option" key={boardingHouse.id} value={boardingHouse.id} disabled>
                                {boardingHouse.name} (current boardingHouse)
                            </option>
                    ))}
                </select>
            </div>
        )
    }

    return (
        <div className="App">
            {/* <div className="sidebar">
                <Sidebar BoardingHouseDropList={BoardingHouseDropList} />
            </div> */}
            <div className="content">

                <div className="nav-bar nav-limiter">
                    <Sidebar BoardingHouseDropList={BoardingHouseDropList} />
                    <Dashboard />
                </div>
                <div className="main-content">
                    <Routes>
                        <Route path="/" element={<Navigate to="/rooms" replace />} /> {/* Redirect root to /rooms */}
                        <Route path="/rooms" element={<PrivateRoute>{selectedBoardingHouseId && <RoomList boardingHouseId={selectedBoardingHouseId} />}</PrivateRoute>} />
                        <Route path="/roomsm" element={<PrivateRoute>{selectedBoardingHouseId && <RoomListM boardingHouseId={selectedBoardingHouseId} />}</PrivateRoute>} />
                        <Route path="/Clients" element={<PrivateRoute><StudentList boardingHouseId={selectedBoardingHouseId} /></PrivateRoute>} />
                        <Route path="/Accounts" element={<PrivateRoute><AccountsList boardingHouseId={selectedBoardingHouseId} /></PrivateRoute>} />
                        <Route path="/VacancyReport" element={<PrivateRoute><VacancyReport boardingHouseId={selectedBoardingHouseId} /></PrivateRoute>} />
                        <Route path="/AccountsReport" element={<PrivateRoute><AccountsReport boardingHouseId={selectedBoardingHouseId} /></PrivateRoute>} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/ortenticurga" element={<Ortenticurga />} />
                        <Route path="/logout" element={<Logout />} />
                        <Route path="/Info" element={<PrivateRoute><Info boardingHouseId={selectedBoardingHouseId} /></PrivateRoute>} />
                        <Route path="/Privacy" element={<Privacy />} />
                        <Route path="/About" element={<About />} />
                    </Routes>
                </div>
            </div>
            <ToastContainer
                position="bottom-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                transition={Bounce}
            />
        </div>
    );
}

export default App;
