// src/components/Dashboard.js
import React, { useState } from 'react';
import '../styles/Dashboard.css';
import { Link } from 'react-router-dom';
import { logout } from '../services/apiService'; // Import the logout function from your axios service
import { useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faSignIn, faSignInAlt, faSignOut, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';



const Dashboard = () => {
    var token = localStorage.getItem('token');
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            await logout(); // Call the logout function to invalidate the token on the server if necessary
            localStorage.removeItem('token'); // Remove the JWT token from local storage
            navigate('/Login'); // Redirect to the login page after logout
            window.location.reload(); // Reload the app
        } catch (err) {
            //console.error('Logout failed');
        }
    };

    const handleProfileSettings = () => {
       // console.log('Opening profile settings...');
    };
    return (
        <div className="dashboard">
            <div className="nav shared-nav">
                <div className="profile-and-notifications">
                    <FontAwesomeIcon icon={faBell} size="lg" className="blue-icons" />
                    <div className="profile-dropdown">
                        <FontAwesomeIcon onClick={toggleDropdown} icon={faUser} size="lg" className="blue-icons" />
                        {isOpen && (
                            <div className="dropdown-menu">
                                <div className="dropdown-item" onClick={handleProfileSettings} >
                                    <FontAwesomeIcon icon={faGear} size="lg" />
                                    Edit Profile
                                </div>
                                <div className="dropdown-item" onClick={handleLogout} >
                                    <FontAwesomeIcon icon={faRightFromBracket} size="lg" />
                                    Logout
                                </div>
                            </div>
                        )}
                    </div>
                    {/* {(!token) ? <div><Link className="blue-icons" to="/login"><FontAwesomeIcon icon={faSignInAlt} size="lg" className="blue-icons" /></Link></div> :
                        <div><Link className="blue-icons" to="/logout"><FontAwesomeIcon icon={faSignOutAlt} size="lg" className="blue-icons" /></Link></div>
                    } */}
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
