import React, { useEffect, useState } from 'react';
import { getStudentsByBoardingHouseId } from '../services/apiService';
import AddStudent from './AddStudent'; // Import AddStudent as default
import '../styles/StudentList.css';
import UpdateStudent from './UpdateStudent';
import Modal from './Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRectangleXmark } from '@fortawesome/free-solid-svg-icons';

const StudentList = ({ boardingHouseId }) => {
    const [students, setStudents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [searchTerm, setSearchTerm] = useState(''); // State to store search term

    const fetchStudents = async () => {
        try {
            const result = await getStudentsByBoardingHouseId(boardingHouseId);
            setStudents(result.data.$values);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const openModalWithContent = (content) => {
        setModalContent(content);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setModalContent(null);
    };

    useEffect(() => {
        if (boardingHouseId) {
            fetchStudents();
        }
    }, [boardingHouseId]);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredStudents = students?.filter((student) => {
        const fullName = `${student?.fname} ${student?.lname}`.toLowerCase();
        const roomName = student?.roomName?.toLowerCase();
        return (
            fullName.includes(searchTerm?.toLowerCase()) ||
            roomName?.includes(searchTerm.toLowerCase())
        );
    });

    // Group students by whether they have a roomId
    const studentsWithRoom = filteredStudents.filter((student) => student.roomId);
    const studentsWithoutRoom = filteredStudents.filter((student) => !student.roomId);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error loading students: {error.message}</div>;

    return (
        <div className="client-container">
            <div className="search-area">
                <div className="input-area">
                    <input
                        type="text"
                        placeholder="Search room or client name..."
                        value={searchTerm}
                        onChange={handleSearch} f
                    />
                    <div className="clear-search">
                        {/* <FilterPills filters={filters} removeFilter={removeFilter} /> */}
                    </div>
                </div>
            </div>
            <div class="clients-main-title">
                <h3 class="">Clients</h3>
            </div>
            <div className="clients">
                <div className="clients-titles">
                    Clients Assigned to Rooms
                </div>
                <div className="client-list">

                    {studentsWithRoom.length > 0 ? (
                        studentsWithRoom.map((student, index) => (
                            <div key={student.id} className="client">
                                <div className="client-details-left">
                                    <div className="client-names" onClick={() => {
                                        openModalWithContent(
                                            <UpdateStudent
                                                studentId={student.id}
                                                setError={setError}
                                                fetchRooms={fetchStudents}
                                            />
                                        );
                                    }}>
                                        <div className="client-index">{index + 1}.</div>
                                        <div className="client-name">{student.fname}&nbsp;&nbsp;{student.lname}</div>


                                    </div>
                                    <div className="client-phone">{student.idNumber}</div>
                                </div>

                                <div className="client-details-right">


                                    <div className="client-room-name">{student.roomName}</div>
                                    <div className="client-entry-date">{new Date(student.dateOfEntry).toLocaleDateString()}</div>
                                    {/* <div className="client-entry-date">Warning(<span>s</span>):{student.numberOfWarnings}</div> */}

                                </div>
                            </div>
                        ))
                    ) : (
                        <div>No Clients assigned to rooms.</div>
                    )}
                </div>

            </div>

            <div className="clients">
                <div className="clients-titles">
                    Clients Without Rooms
                </div>
                <div className="client-list">

                    {studentsWithoutRoom.length > 0 ? (
                        studentsWithoutRoom.map((student, index) => (
                            <div key={student.id} className="client">
                                <div className="client-details-left">
                                    <div className="client-names" onClick={() => {
                                        openModalWithContent(
                                            <UpdateStudent
                                                studentId={student.id}
                                                setError={setError}
                                                fetchRooms={fetchStudents}
                                            />
                                        );
                                    }}>
                                        <div className="client-index">{index + 1}.</div>
                                        <div className="client-name">{student.fname}&nbsp;&nbsp;{student.lname}</div>


                                    </div>
                                    <div className="client-phone">{student.idNumber}</div>
                                </div>

                                <div className="client-details-right">


                                    <div className="client-room-name">{student.roomName}</div>
                                    <div className="client-entry-date">{new Date(student.dateOfEntry).toLocaleDateString()}</div>
                                    {/* <div className="client-entry-date">Warning(<span>s</span>):{student.numberOfWarnings}</div> */}

                                </div>
                            </div>
                        ))
                    ) : (
                        <div>No Clients assigned to rooms.</div>
                    )}
                </div>

            </div>
            <Modal isOpen={isModalOpen} onClose={closeModal}>
                {modalContent}
            </Modal>

        </div>
    );
};

export default StudentList;
