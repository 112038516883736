// Modal.js
import React from 'react';
import '../styles/Modal.css';

const Modal = ({ children, isCalender, isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>

                <div className="modal-close-button">
                    <button className="close-button" onClick={onClose}>X</button>
                </div>

                <div className="modal-children">
                    {children}
                </div>



            </div>
        </div>
    );
};

export default Modal;
