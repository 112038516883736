import React, { useState, useEffect } from 'react';
import { getRentDetailsByStudentId, getBoardingHouseById } from '../services/apiService'; // Assuming the API functions are in api.js
import ClipLoader from 'react-spinners/ClipLoader';
import '../styles/AddRent.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/free-solid-svg-icons'

const StudentRentDetails = ({ studentId, roomName, student, boardingHouseId }) => {
    // State for rents
    const [rents, setRents] = useState([]);
    const [loading, setLoading] = useState(true); // Loading state  
    const [email, setEmail] = useState('');
    const [location, setLocation] = useState('');
    const [name, setName] = useState('');

    useEffect(() => {
        fetchBoardingHouseInfo()
    }, [boardingHouseId]);



    const fetchBoardingHouseInfo = async () => {
        try {
            const result = await getBoardingHouseById(boardingHouseId);

            const house = result.data;

            console.log(house);

            //setBoardingHouse(house);
            setName(house.name);
            setEmail(house.email);
            setLocation(house.location);
        } catch (error) {
            //setError(error);
        } finally {
            //setLoading(false);
        }
    };


    // Fetch rent details based on studentId
    const fetchRentDetails = async () => {
        try {
            const response = await getRentDetailsByStudentId(studentId);
            setRents(response.data.$values.filter(rent => rent.hasOwnProperty('amount')));
        } catch (error) {
            console.error('Error fetching rents:', error);
        }
        finally {
            setLoading(false); // Turn off loading after the request is complete
        }
    };

    // Date formatting function
    const formatDate = (dateString) => {
        const options = {
            year: 'numeric',
            month: 'long',  // Full month name
            day: 'numeric', // Day of the month
        };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    // Fetch rent details when the component mounts or when studentId changes
    useEffect(() => {
        const timer = setTimeout(() => {
            fetchRentDetails();
        }, 300);  // 300ms debounce time
        return () => clearTimeout(timer); // Clear timeout if effect is called again
    }, [studentId]);

    function handlePrint(Amount, StartDate, EndDate) {
        const receiptData = {
            boardingHouseName: name,
            receiptNumber: Math.floor(Math.random() * 100000),
            date: new Date().toISOString().split('T')[0],
            tenantName: `${student.fname} ${student.lname}`,
            roomNumber: roomName,
            amountPaid: Amount,
            //rentAmount: roomCost,
            periodCovered: `${formatReadableDate(StartDate)} to ${formatReadableDate(EndDate)}`,
            location: location, // Replace with actual data
            email: email // Replace with actual data
        };

        openReceiptInNewWindow(receiptData);
    }

    const formatReadableDate = (date) => {
        const d = new Date(date);
        const day = d.getDate();
        const month = d.toLocaleString('default', { month: 'long' });
        const suffix = (day === 1 || day === 21 || day === 31) ? 'st' : (day === 2 || day === 22) ? 'nd' : (day === 3 || day === 23) ? 'rd' : 'th';
        return `${day}${suffix} ${month}`;
    };

    const openReceiptInNewWindow = (receiptData) => {
        const newWindow = window.open('', '_blank', 'width=389,height=620');
        const receiptHTML = `
            <html>
                <head>
                    <title>Receipt</title>
                    <style>
                        body {
                            font-family: Arial, sans-serif;
                            margin: 20px;
                        }
                        .receipt-container {
                            border: 1px solid #ddd;
                            padding: 20px;
                            max-width: 600px;
                            margin: 0 auto;
                        }
                        header, footer {
                            text-align: center;
                        }
                        hr {
                            margin: 20px 0;
                        }
                        input {
                            border: none;
                            background-color: transparent;
                            text-align: center;
                            font-weight: bold;
                            width: 100%;
                            max-width: 300px;
                            outline: none;
                        }
                        input:focus {
                            border-bottom: 1px solid #000;
                        }
                    </style>
                </head>
                <body>
                    <div class="receipt-container">
                        <header>
                            <h2>${receiptData.boardingHouseName}</h2>
                            <p>Address: ${receiptData.location}</p>
                            <p>Contact: <input type="text" id="contactInput" placeholder="Enter contact" value="${receiptData.email ? receiptData.email : ''}" /></p>
                        </header>
    
                        <hr />
    
                        <section class="receipt-details">
                            <p><strong>Receipt Number:</strong> #${receiptData.receiptNumber}</p>
                            <p><strong>Date:</strong> ${receiptData.date}</p>
                        </section>
    
                        <hr />
    
                        <section class="tenant-details">
                            <p><strong>Tenant Name:</strong> ${receiptData.tenantName}</p>
                            <p><strong>Room Number:</strong> ${receiptData.roomNumber}</p>
                        </section>
    
                        <section class="payment-details">
                            <p><strong>Amount Paid:</strong> ${receiptData.amountPaid}</p>
             
                            <p><strong>Period Covered:</strong> ${receiptData.periodCovered}</p>
                            <p><strong>Payment Method:</strong> <input type="text" id="paymentMethodInput" placeholder="Enter payment method" /></p>
                        </section>
    
                        <footer>
                            <p>Thank you for your payment!</p>
                            <p><strong>Issued by:</strong> ${receiptData.boardingHouseName}</p>
    
                            <hr />
                            
                            <p><strong>Created by:</strong> ZerOne Technologies Limited</p>
                        </footer>
                    </div>
                </body>
            </html>
        `;
        newWindow.document.write(receiptHTML);
        newWindow.document.close();
    };


    return (
        <div className="payment-history">
            <div className="name-and-title">
                <h3>Client Payment History</h3>
                <h4>{`${student.fname} ${student.lname}`}</h4>
            </div>

            {loading ? (
                <div className="loader">
                    <ClipLoader className="load-animation-prop" loading={loading} />
                </div>
            ) :
                rents.length > 0 ? (

                    <div className="room-occupants-h">
                        {rents
                            .map((rent) => (
                                <div className="room-occupant-h" key={rent.id}>

                                    <div className="occupant-balance-h">{formatDate(rent.startDate)}
                                    </div>
                                    <div>to</div>
                                    <div className="occupant-names-h">{formatDate(rent.endDate)}</div>
                                    <div className="occupant-balance-h">k{rent.amount}
                                    </div>
                                    <div className="add-occupant-payment-h">
                                        <FontAwesomeIcon onClick={() => { handlePrint(rent.amount, rent.startDate, rent.endDate) }} icon={faPrint} />

                                    </div>
                                </div>
                            ))
                        }
                    </div>
                ) : (
                    <p>No rent details available.</p>
                )}
        </div>
    );
};

export default StudentRentDetails;
