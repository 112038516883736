import React, { useState } from 'react';
import { createCost } from '../services/apiService'; // Assuming the API functions are in api.js
import { toast  } from 'react-toastify';

// Enum values as arrays
const COST_TYPE_OPTIONS = ["Income", "Expense"];
const COST_CATEGORY_OPTIONS = [
  "Rent",
  "Salaries",
  "CleaningMaterials",
  "Utilities",
  "Maintenance",
  "FoodSupplies",
  "CollateralPayment",
  "OppositeEntry",
  "Other"
];

const AddCost = ({ boardingHouseId, fetchRooms, setError }) => {   
    const [costDetails, setCostDetails] = useState({
        amount: 0,
        description: '',
        type: '',
        category: '',
        boardingHouseId
    });

    const handleAddCost = async () => {
        if (!costDetails?.amount || !costDetails.description.trim() || !costDetails.type.trim() || !costDetails.category.trim()) {
            toast.error(`All fields are required`);
            return;
        }

        // Get the index of the selected type and category
        const typeIndex = COST_TYPE_OPTIONS.indexOf(costDetails.type);
        const categoryIndex = COST_CATEGORY_OPTIONS.indexOf(costDetails.category);

        // Create a new costDetails object with indexes
        const costDetailsWithIndexes = {
            ...costDetails,
            type: typeIndex,
            category: categoryIndex
        };

        try {
            await createCost(costDetailsWithIndexes);
            toast.success(`Record added successfully!`);
            await fetchRooms(); // Re-fetch the rooms to update the list with the new cost
        } catch (error) {
            setError(error);
            toast.error(`Failed to add record`);
        }
    };

    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCostDetails({
            ...costDetails,
            [name]: value,
        });

        //console.log(costDetails);
    };

    return (
        <div className="add-amount input-group">
            <input
                type="number"
                name="amount"
                value={costDetails.amount}
                onChange={handleInputChange}
                placeholder="Cost"
            />
            <input
                type="text"
                name="description"
                value={costDetails.description}
                onChange={handleInputChange}
                placeholder="Description"
            />
            <select
                name="type"
                value={costDetails.type}
                onChange={handleInputChange}
            >
                <option value="">Select Type</option>
                {COST_TYPE_OPTIONS.map((type) => (
                    <option key={type} value={type}>
                        {type}
                    </option>
                ))}
            </select>
            <select
                name="category"
                value={costDetails.category}
                onChange={handleInputChange}
            >
                <option value="">Select Category</option>
                {COST_CATEGORY_OPTIONS.map((category) => (
                    <option key={category} value={category}>
                        {category}
                    </option>
                ))}
            </select>
            <button className="primary-btn" onClick={handleAddCost}>Add Cost</button>
        </div>
    );
};

export default AddCost;
