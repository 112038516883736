import React from 'react';
import { logout } from '../services/apiService'; // Import the logout function from your axios service
import { useNavigate } from 'react-router-dom';

const Logout = () => {
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            await logout(); // Call the logout function to invalidate the token on the server if necessary
            localStorage.removeItem('token'); // Remove the JWT token from local storage
            navigate('/Login'); // Redirect to the login page after logout
            window.location.reload(); // Reload the app
        } catch (err) {
            //console.error('Logout failed');
        }
    };

    return (
        <button onClick={handleLogout}>Logout</button>
    );
};

export default Logout;
