import { getRoomsByBoardingHouseId } from '../services/apiService';
import React, { useEffect, useState } from 'react';
import '../styles/VacancyReport.css';


const VacancyReport = ({ boardingHouseId }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [rooms, setRooms] = useState([]);

    const fetchRooms = async () => {
        try {
            const result = await getRoomsByBoardingHouseId(boardingHouseId);
            //console.log('API Result:', result); // Debugging: log the API result
            const roomsData = result.data.$values; // Adjusting to your data structure

            if (Array.isArray(roomsData)) {
                // Flattening the rooms array to include references
                const flattenedRooms = flattenRooms(roomsData);
                setRooms(flattenedRooms);
            } else {
                throw new Error('Unexpected data structure');
            }
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    // Function to flatten rooms data structure
    const flattenRooms = (roomsArray) => {
        const roomsMap = {};

        const addRoomToMap = (room) => {
            if (!roomsMap[room.id]) {
                roomsMap[room.id] = room;
            }
        };

        roomsArray.forEach(room => {
            if (room.$ref) {
                // If it's a reference, it should already be in the map
                return;
            }
            addRoomToMap(room);

            // If the room has nested rooms, add them as well
            if (room.boardingHouse?.rooms?.$values) {
                room.boardingHouse.rooms.$values.forEach(nestedRoom => {
                    if (nestedRoom.$ref) {
                        // Handle references if needed
                        const refId = nestedRoom.$ref.split("/")[1];
                        const referencedRoom = roomsArray.find(r => r.$id === refId);
                        addRoomToMap(referencedRoom);
                    } else {
                        addRoomToMap(nestedRoom);
                    }
                });
            }
        });

        return Object.values(roomsMap);
    }

    // Utility function to determine status color
    const getStatusColor = (numOfSpaces, availability) => {

        if (!numOfSpaces) {

            return 'vacancy-yellow'; //room is under maintenence
        }
        else if (numOfSpaces && availability === 0) {
            return 'vacancy-red'; // room is fully occupied
        } else {
            return 'vacancy-green'; // room has free spaces
        }
    };

    useEffect(() => {
        fetchRooms();
    }, [boardingHouseId]);

    return (
        <div className="vacancy-container">
            <div className="vacancy-title">
                <h3>Room capacity (vacancies)</h3>
            </div>
            <div className=" vacancies">
                <div className="vacancy-list">
                    {rooms.length > 0 ? (
                        rooms.map((room, index) => (

                            <div key={room.id} className="vacancy-entry">

                                <div className="vacancy-details-left">

                                    <div className="vacancy-room">
                                        <div className="client-index">{index + 1}.&nbsp;</div>
                                        <div>{room.name}</div>
                                    </div>
                                    <div className="vacancy-gender">
                                        {room.gender ? 'Female' : 'Male'}
                                    </div>
                                </div>
                                <div className="vacancy-details-right">
                                    {/* <div className={`vacancy-capacity ${getStatusColor(room.capacity, room.capacity - room.students.$values.length)}`}> */}
                                    <div className="vacancy-capacity">
                                        <span className="hide-on-mobile">Room</span> Capacity: <span className="make-bold">{room.capacity}</span>
                                    </div>
                                    {/* <div className={`vacancy-occupied ${getStatusColor(room.capacity, room.capacity - room.students.$values.length)}`}> */}
                                    <div className="vacancy-occupied">
                                        Occupied <span className="hide-on-mobile">Space</span>: <span className="make-bold">{room.students.$values.length}</span>
                                    </div>
                                    <div className={`vacancy-free ${getStatusColor(room.capacity, room.capacity - room.students.$values.length)}`}>
                                        Free Space: <span className="make-bold">{room.capacity - room.students.$values.length}</span>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div>No rooms available</div>
                    )}
                </div>

            </div>

        </div >
    );
};

export default VacancyReport;