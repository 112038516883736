import React, { useState, useEffect } from 'react';
import '../styles/AddRent.css';
import { createRentForStudent, getBoardingHouseById } from '../services/apiService';
import { toast } from 'react-toastify';

const AddRent = ({ student, boardingHouseId, roomName, lastDueDate = null, roomCost, securityDeposit, fetchRooms, setError }) => {
    const [Amount, setAmount] = useState('');
    const [StartDate, setStartDate] = useState('');
    const [EndDate, setEndDate] = useState('');
    const [activeButton, setActiveButton] = useState(lastDueDate ? 'lastDueDate' : 'today');
    const [securityButton, setSecurityButton] = useState(lastDueDate ? 'noDeposit' : 'deposit');
    const [confirmAdd, setConfirmAdd] = useState(false); // State for confirmation step
    const [email, setEmail] = useState('');
    const [location, setLocation] = useState('');
    const [name, setName] = useState('');


    useEffect(() => {
        fetchBoardingHouseInfo()
    }, [boardingHouseId]);



    const fetchBoardingHouseInfo = async () => {
        try {
            const result = await getBoardingHouseById(boardingHouseId);

            const house = result.data;

            console.log(house);

            //setBoardingHouse(house);
            setName(house.name);
            setEmail(house.email);
            setLocation(house.location);
        } catch (error) {
            setError(error);
        } finally {
            //setLoading(false);
        }
    };

    const formatReadableDate = (date) => {
        const d = new Date(date);
        const day = d.getDate();
        const month = d.toLocaleString('default', { month: 'long' });
        const suffix = (day === 1 || day === 21 || day === 31) ? 'st' : (day === 2 || day === 22) ? 'nd' : (day === 3 || day === 23) ? 'rd' : 'th';
        return `${day}${suffix} ${month}`;
    };

    const formatDate = (date) => {
        const d = new Date(date);
        d.setMinutes(d.getMinutes() - d.getTimezoneOffset());
        return d.toISOString().split('T')[0]; // Format as "YYYY-MM-DD"
    };

    const setDate = (dateType) => {
        const date = dateType === 'today' ? formatDate(new Date()) : formatDate(lastDueDate);
        setStartDate(date);
        setActiveButton(dateType);
    };

    useEffect(() => {
        if (Amount && StartDate) {
            const adjustedAmount = securityButton === 'deposit' ? parseFloat(Amount) - securityDeposit : parseFloat(Amount);
            const days = (adjustedAmount / roomCost) * 30; // assuming 30 days in a month
            const newEndDate = new Date(StartDate);
            newEndDate.setDate(newEndDate.getDate() + Math.floor(days));
            setEndDate(formatDate(newEndDate));
        }
    }, [Amount, StartDate, securityButton]);

    const openReceiptInNewWindow = (receiptData) => {
        const newWindow = window.open('', '_blank', 'width=389,height=620');
        const receiptHTML = `
            <html>
                <head>
                    <title>Receipt</title>
                    <style>
                        body {
                            font-family: Arial, sans-serif;
                            margin: 20px;
                        }
                        .receipt-container {
                            border: 1px solid #ddd;
                            padding: 20px;
                            max-width: 600px;
                            margin: 0 auto;
                        }
                        header, footer {
                            text-align: center;
                        }
                        hr {
                            margin: 20px 0;
                        }
                        input {
                            border: none;
                            background-color: transparent;
                            text-align: center;
                            font-weight: bold;
                            width: 100%;
                            max-width: 300px;
                            outline: none;
                        }
                        input:focus {
                            border-bottom: 1px solid #000;
                        }
                    </style>
                </head>
                <body>
                    <div class="receipt-container">
                        <header>
                            <h2>${receiptData.boardingHouseName}</h2>
                            <p>Address: ${receiptData.location}</p>
                            <p>Contact: <input type="text" id="contactInput" placeholder="Enter contact" value="${receiptData.email ? receiptData.email : ''}" /></p>
                        </header>
    
                        <hr />
    
                        <section class="receipt-details">
                            <p><strong>Receipt Number:</strong> #${receiptData.receiptNumber}</p>
                            <p><strong>Date:</strong> ${receiptData.date}</p>
                        </section>
    
                        <hr />
    
                        <section class="tenant-details">
                            <p><strong>Tenant Name:</strong> ${receiptData.tenantName}</p>
                            <p><strong>Room Number:</strong> ${receiptData.roomNumber}</p>
                        </section>
    
                        <section class="payment-details">
                            <p><strong>Amount Paid:</strong> ${receiptData.amountPaid}</p>
                            <p><strong>Rent Amount:</strong> ${receiptData.rentAmount}</p>
                            <p><strong>Security Deposit:</strong> ${receiptData.securityDeposit}</p>
                            <p><strong>Period Covered:</strong> ${receiptData.periodCovered}</p>
                            <p><strong>Payment Method:</strong> <input type="text" id="paymentMethodInput" placeholder="Enter payment method" /></p>
                        </section>
    
                        <footer>
                            <p>Thank you for your payment!</p>
                            <p><strong>Issued by:</strong> ${receiptData.boardingHouseName}</p>
    
                            <hr />
                            
                            <p><strong>Created by:</strong> ZerOne Technologies Limited</p>
                        </footer>
                    </div>
                </body>
            </html>
        `;
        newWindow.document.write(receiptHTML);
        newWindow.document.close();
    };
    
    const handleAddRent = async () => {
        if (!Amount || !StartDate || !EndDate) {
            toast.error('All fields are required');
            return;
        }

        if (confirmAdd) {
            const rent = {
                StudentId: student.id,
                Amount,
                StartDate: new Date(StartDate).toISOString(),
                EndDate: new Date(EndDate).toISOString(),
            };

            try {
                await createRentForStudent(student.id, rent);
                toast.success('Rent added successfully!');
                setAmount('');
                setStartDate('');
                setEndDate('');
                setConfirmAdd(false);
                await fetchRooms();

                const receiptData = {
                    boardingHouseName: name,
                    receiptNumber: Math.floor(Math.random() * 100000),
                    date: new Date().toISOString().split('T')[0],
                    tenantName: `${student.fname} ${student.lname}` ,
                    roomNumber: roomName,
                    amountPaid: Amount,
                    rentAmount: roomCost,
                    securityDeposit: securityButton === 'deposit' ? securityDeposit : 0,
                    periodCovered: `${formatReadableDate(StartDate)} to ${formatReadableDate(EndDate)}`,
                    balance: 0,
                    location: location, // Replace with actual data
                    email: email // Replace with actual data
                };

                openReceiptInNewWindow(receiptData); // Open receipt in a new window
            } catch (error) {
                setError(error);
                toast.error('Failed to add rent');
            }
        } else {
            setConfirmAdd(true);
        }
    };

    useEffect(() => {
        setDate(lastDueDate ? 'lastDueDate' : 'today');
    }, [lastDueDate]);

    useEffect(() => {
        if (lastDueDate) {
            setSecurityButton('noDeposit');
        }
    }, [lastDueDate]);

    const confirmationMessage = securityButton === 'deposit'
        ? `Add K${Amount - securityDeposit} for rent and K${securityDeposit} security deposit from ${formatReadableDate(StartDate)} to ${formatReadableDate(EndDate)}?`
        : `Add K${Amount} rent from ${formatReadableDate(StartDate)} to ${formatReadableDate(EndDate)}?`;

    return (
        <div className="input-group">
            <div className="component-title">Add Rent</div>

            <div className="lable-and-input">
                <label>Amount Paid</label>
                <input
                    type="decimal"
                    value={Amount}
                    onChange={(e) => setAmount(e.target.value)}
                    placeholder="Amount Paid"
                />
            </div>

            <div className="lable-and-input">
                <label>Date Paid</label>
                <div className="date-input-and-buttons">
                    <input
                        type="date"
                        value={StartDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        placeholder="Start Date"
                    />
                    <div className="add-rent-date-buttons">
                        <button
                            type="button"
                            onClick={() => setDate('today')}
                            className={activeButton === 'today' ? 'primary-btn' : ''}
                        >
                            Today
                        </button>
                        <button
                            type="button"
                            onClick={() => setDate('lastDueDate')}
                            className={activeButton === 'lastDueDate' ? 'primary-btn' : ''}
                            disabled={lastDueDate === null}
                        >
                            Last Due Date
                        </button>
                    </div>

                </div>
            </div>

            <div className="lable-and-input">
                <label>Expiration Date</label>
                <input
                    type="date"
                    value={EndDate}
                    // disabled
                    onChange={(e) => setEndDate(e.target.value)}
                    placeholder="End Date"
                />
                <div className="add-rent-date-buttons">
                    <button
                        type="button"
                        onClick={() => setSecurityButton('deposit')}
                        className={securityButton === 'deposit' ? 'primary-btn' : ''}
                    >
                        With Security Deposit
                    </button>
                    <button
                        type="button"
                        onClick={() => setSecurityButton('noDeposit')}
                        className={securityButton === 'noDeposit' ? 'primary-btn' : ''}
                    >
                        No Security Deposit
                    </button>
                </div>

            </div>

            {confirmAdd && (
                <div className="confirmation-message">
                    {confirmationMessage}
                </div>
            )}

            <button className="primary-btn" onClick={handleAddRent}>
                {confirmAdd ? 'Confirm Add Rent' : 'Add Rent'}
            </button>
        </div>
    );
};

export default AddRent;
